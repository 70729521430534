import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription }   from 'rxjs';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, OnDestroy  {

  public activeURL: string = '/home';
  public subNavIsClosed: boolean = true;

  private popupOpenSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;

  private holidayStart = moment('18.08.2020', 'DD.MM.YYYY');
  private holidayEnd = moment('20.08.2021', 'DD.MM.YYYY');
  public isHoliday: boolean;

  constructor(private router: Router, private ccService: NgcCookieConsentService) {
    this.isHoliday = moment().isBetween(this.holidayStart, this.holidayEnd);

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        console.log(window.scrollY > 300);
        if (window.scrollY > 300) {
          window.scroll(0,0);
        }
        this.activeURL = val.url;
        this.subNavIsClosed = true;
      }
  });
  }
  ngOnInit() {
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        console.log('test');
      });
    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        console.log('Decline');
      });
    }
    ngOnDestroy() {
      // unsubscribe to cookieconsent observables to prevent memory leaks
      this.popupOpenSubscription.unsubscribe();
      this.revokeChoiceSubscription.unsubscribe();
    }
  
  public toggleSubNav() {
    this.subNavIsClosed = !this.subNavIsClosed;
  }

}
