<div class="header">
  <img class="banner" src="assets/banner/6.jpg" />
  <img class="title" src="assets/icons/Logo.png" alt="" />
</div>
<div class="nav-bar">
  <div class="nav-padding">
    <div class="nav-bar-link">
      <a
        [class.bold-and-underline]="activeURL === '/home'"
        routerLink="/home"
        routerLinkActive="active"
        >Übersicht</a
      >
    </div>
    <div class="nav-bar-link">
      <a
        [class.bold-and-underline]="activeURL === '/sprechzeiten'"
        routerLink="/sprechzeiten"
        routerLinkActive="active"
        >Sprechzeiten</a
      >
    </div>
    <div class="nav-bar-link">
      <a
        [class.bold-and-underline]="activeURL === '/leistungen'"
        routerLink="/leistungen"
        routerLinkActive="active"
        >Leistungen</a
      >
    </div>
    <div class="nav-bar-link">
      <a
        [class.bold-and-underline]="activeURL === '/downloads'"
        routerLink="/downloads"
        routerLinkActive="active"
        >Downloads</a
      >
    </div>
  </div>
</div>
<div class="sub-nav-container" [class.hidden]="activeURL !== '/leistungen'">
  <div class="sub-nav" [class.closed]="subNavIsClosed">
    <div class="sub-nav-padding text-right">
      <p (click)="toggleSubNav()">
        ALLES LEISTUNGEN<img
          class="icon chevron"
          src="assets/icons/up.svg"
          alt="down"
        />
      </p>
      <a href="/info?id=KlassischeHomoeopathie.html">Klassische Homöopathie</a>
      <a href="/info?id=TCM.html">Traditionelle Chinesische Medizin (TCM)</a>
      <a href="/info?id=Akupunktur.html">Akupunktur</a>
      <a href="/info?id=Akupressur.html">Akupressur</a>
      <a href="/info?id=Moxa.html">Moxa</a>
      <a href="/info?id=Schroepfen.html">Schröpfen</a>
      <a href="/info?id=KraeuterTCM.html">Chinesische Kräuterheilkunde</a>
      <a href="/info?id=ChinesischeDiaetetik.html">Chinesische Diätetik</a>
    </div>
  </div>
</div>

<div class="alert green main-container">
  <p>
    <strong>Freitags keine Sprechstunde</strong><br />Aufgrund von
    Personalmangel findet bis auf weiteres freitags keine Sprechstunde statt.
  </p>
</div>

<div *ngIf="isHoliday" class="alert green main-container">
  <!-- <p><strong>Info:</strong> Die Praxis ist in der Woche vom 03. bis 07. Mai geschlossen.<br>Ab dem 10. Mai sind wir wieder für Sie da.</p> -->
  <p>
    <strong>Info:</strong> Die Praxis ist vom 06. bis 20. November
    geschlossen.<br />Ab dem 23. August sind wir wieder für Sie da.
  </p>
</div>

<router-outlet></router-outlet>
<div class="footer">
  <div class="footer-padding">
    <p>
      <a
        [class.bold-and-underline]="activeURL === '/datenschutz'"
        routerLink="/datenschutz"
        routerLinkActive="active"
        >Datenschutz</a
      >
    </p>
    <p>
      <a
        [class.bold-and-underline]="activeURL === '/impressum'"
        routerLink="/impressum"
        routerLinkActive="active"
        >Impressum</a
      >
    </p>
  </div>
</div>
