<div class="main-container">
  <div class="card imprint">
    <div class="button-back">
      <p><a routerLink="/home" routerLinkActive="active">← Startseite</a></p>
    </div>
    <h1>Impressum</h1>
    <h3>Praxis</h3>
    <p>
      Dr. Andreas Mauch<br />
      Am Anger 6<br />
      84069 Schierling
    </p>

    <h3>Kontakt</h3>
    <p>
      Telefon: 09451 - 3998<br />
      Telefax: 09451 - 2771<br />
      E-Mail: info@dr-andreas-mauch.de
    </p>

    <h3>Ärzte</h3>
    <p>Dr. Andreas Mauch<br /></p>

    <h3>Kammerzugehörigkeit</h3>
    <p>Bayerische Landesärztekammer München</p>

    <h3>Zuständige Kassenärztliche Vereinigung</h3>
    <p>Kassenärztliche Vereinigung Regensburg</p>

    <h3>Lebenslange Arztnummer</h3>
    <p>Dr. Andreas Mauch, LANR 849553402<br /></p>

    <h3>Betriebsstättennummer</h3>
    <p>688211000</p>

    <h3>Gesetzliche Berufsbezeichnung</h3>
    <p>Arzt</p>

    <h3>Staat und Bundesland der Verleihung der Berufsbezeichnung</h3>
    <p>Deutschland/Bayern</p>

    <h3>Berufsrechtliche Regelung</h3>
    <p>
      Berufsordnung für Ärzte, zu finden auf der Internetseite der Bayerischen
      Landesärztekammer München
      <a class="link" href="http://www.blaek.de/" target="_blank"
        >www.blaek.de</a
      >
    </p>

    <h1>Rechtliche Hinweise / Disclaimer</h1>

    <h3>Haftungsbeschränkung für eigene Inhalte</h3>
    <p>
      Alle Inhalte unseres Internetauftritts wurden mit Sorgfalt und nach bestem
      Gewissen erstellt. Eine Gewähr für die Aktualität, Vollständigkeit und
      Richtigkeit sämtlicher Seiten kann jedoch nicht übernommen werden. Gemäß §
      7 Abs. 1 TMG sind wir als Dienstanbieter für eigene Inhalte auf diesen
      Seiten nach den allgemeinen Gesetzen verantwortlich, nach den §§ 8 bis 10
      TMG jedoch nicht verpflichtet, die übermittelten oder gespeicherten
      fremden Informationen zu überwachen. Eine umgehende Entfernung dieser
      Inhalte erfolgt ab dem Zeitpunkt der Kenntnis einer konkreten
      Rechtsverletzung und wir haften nicht vor dem Zeitpunkt der
      Kenntniserlangung.
    </p>

    <h3>Haftungsbeschränkung für externe Links</h3>
    <p>
      Unsere Webseite enthält sog. „externe Links“ (Verknüpfungen zu Webseiten
      Dritter), auf deren Inhalt wir keinen Einfluss haben und für den wir aus
      diesem Grund keine Gewähr übernehmen. Für die Inhalte und Richtigkeit der
      Informationen ist der jeweilige Informationsanbieter der verlinkten
      Webseite verantwortlich. Als die Verlinkung vorgenommen wurde, waren für
      uns keine Rechtsverstöße erkennbar. Sollte uns eine Rechtsverletzung
      bekannt werden, wird der jeweilige Link umgehend von uns entfernt.
    </p>

    <h3>Urheberrecht</h3>
    <p>
      Die auf dieser Webseite veröffentlichten Inhalte und Werke unterliegen dem
      deutschen Urheberrecht. Jede Art der Vervielfältigung, Bearbeitung,
      Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
      Urheberrechts bedarf der vorherigen schriftlichen Zustimmung des
      jeweiligen Urhebers bzw. Autors.
    </p>

    <h3>Datenschutz</h3>
    <p>
      Durch den Besuch unseres Internetauftritts können Informationen über den
      Zugriff (Datum, Uhrzeit, aufgerufene Seite) auf dem Server gespeichert
      werden. Dies stellt keine Auswertung personenbezogener Daten (z.B. Name,
      Anschrift oder E-Mail Adresse) dar. Sofern personenbezogene Daten erhoben
      werden, erfolgt dies – sofern möglich – nur mit dem vorherigen
      Einverständnis des Nutzers der Webseite. Eine Weiterleitung der Daten an
      Dritte findet ohne ausdrückliche Zustimmung des Nutzers nicht statt. Wir
      weisen ausdrücklich darauf hin, dass die Übertragung von Daten im Internet
      (z.B. per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz
      der Daten vor dem Zugriff Dritter kann nicht gewährleistet werden. Wir
      können keine Haftung für die durch solche Sicherheitslücken entstehenden
      Schäden übernehmen. Der Verwendung veröffentlichter Kontaktdaten durch
      Dritte zum Zwecke von Werbung wird ausdrücklich widersprochen. Wir
      behalten uns rechtliche Schritte für den Fall der unverlangten Zusendung
      von Werbeinformationen, z.B. durch Spam-Mails, vor.
    </p>
  </div>
</div>
