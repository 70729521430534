<div class="main-container">
  <div class="card imprint">
    <div class="button-back">
      <p><a routerLink="/home" routerLinkActive="active">← Startseite</a></p>
    </div>
    <h1>Downloads</h1>

    <a
      class="link"
      href="https://firebasestorage.googleapis.com/v0/b/dr-andreas-mauch.appspot.com/o/Dr_Mauch_Anmeldung_und_Datenschutz.pdf?alt=media&token=07eddbd9-7850-49e8-b058-33c487a5b4eb"
      target="_blank"
      ><h3>Anmeldung & Datenschutz</h3></a
    >
    <br />
    <a
      class="link"
      href="https://firebasestorage.googleapis.com/v0/b/dr-andreas-mauch.appspot.com/o/Dr_Mauch_Aufklaerung_Akupunktur_Schroepfen.pdf?alt=media&token=e3c2c066-8c03-45af-a879-64e6d64ff559"
      target="_blank"
      ><h3>Aufklärung Akupunktur & Schröpfen</h3></a
    >

    <br />
    <br />
    <br />
  </div>
</div>
