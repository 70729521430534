<div class="main-container">
  <div class="card imprint">
    <div class="button-back">
      <p><a routerLink="/home" routerLinkActive="active">← Startseite</a></p>
    </div>
    <h1>Sprechzeiten Dr. Mauch</h1>

    <table style="width: 100%">
      <tr>
        <td>Montag</td>
        <td style="text-align: right">
          08:00 - 12:30 Uhr &&nbsp;16:00 - 19:00 Uhr
        </td>
      </tr>
      <tr>
        <td>Dienstag</td>
        <td style="text-align: right">08:00 - 12:30 Uhr</td>
      </tr>
      <tr>
        <td>Mittwoch</td>
        <td style="text-align: right">15:00 - 19:00 Uhr</td>
      </tr>
      <tr>
        <td>Donnerstag</td>
        <td style="text-align: right">08:00 - 12:30 Uhr</td>
      </tr>
    </table>
    <br />
    <br />
    <h3>Terminvereinbarung</h3>
    <a href="tel:+4994513998">
      <p>
        <img class="icon" src="assets/icons/phone.svg" alt="phone-icon" />+49
        9451 - 3998
      </p></a
    >
    <br />
    <h3>Sondersprechstunde</h3>
    <p>
      Gerne biete ich Ihnen einen Termin für eine individuelle Beratung im Sinne
      der klassischen Homöopathie außerhalb unserer Sprechzeiten an. Sie können
      mir dann über Ihre persönliche Krankheitsgeschichte ausfühlich
      berichten.<br />
      Die Kosten der Homöopatischen Beratung wird auch von zahlreichen
      Krankenkassen übernommen. Dazu gehören alle privaten Krankenkassen, die
      Barmer GEK, die Techniker EK, die ClassicIKK, wie auch viele
      Bebrtiebskrankenkassen. Ob Ihre Krankenkasse diese Kosten übernimmt
      erfahren Sie im
      <a
        class="bold"
        href="https://www.dzvhae.de/homoeopathie-fuer-patienten/kosten---erstattung-1-402/gkv-versorgung/teilnehmende-krankenkassen/selektivvertraege-homoeopathie-mit-der-dzvhae-managementgesellschaft-mbh-beteiligte-gesetzliche-krankenkassen.html"
        >Internationalen Homöopathie Portal</a
      >
      oder auf der Seite der
      <a class="bold" href="http://www.kbv.de/html/101.php"
        >Kassenärztlichen Bundesvereinigung</a
      >. Diese werden regelmäßig aktualisiert.
    </p>

    <h3>Labor</h3>
    <!-- <p>
      Regelmäßige Laboruntersuchungen sind Montag, Dienstag, Donnerstag und
      Freitag zwischen 8 und 11 Uhr möglich. Wir bitten um Terminvereinbarung.
    </p> -->
    <p>
      Regelmäßige Laboruntersuchungen sind Montag, Dienstag und Donnerstag
      zwischen 8 und 11 Uhr möglich. Wir bitten um Terminvereinbarung.
    </p>
  </div>
</div>
