import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dynamic-content',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.sass']
})
export class DynamicContentComponent implements OnInit {

  public innerHTML: string = "";
  private configUrl = "assets/dynamic-content/Akupunktur.html";
  private configUrlBase = "assets/dynamic-content/";

  constructor(private route: ActivatedRoute,
            private http: HttpClient,
            private router: Router) { }

  ngOnInit() {
    console.log('init')
    this.route
      .queryParams
      .subscribe(params => {
        this.setContent(params['id']);
      });
  }

  public setContent(url: string) {
    this.configUrl = this.configUrlBase + url;

    this.http.get( this.configUrl, {responseType: 'text'}).subscribe((content) => {
      this.innerHTML = content
      window.scroll(0,0);
    }) ;
  }
}
