<div class="main-container">
    <div class="card">
        <div class="button-back">
            <p><a routerLink="/home" routerLinkActive="active">← Startseite</a></p>
        </div>
        <h1 class="nav-bar-link show-mobile">Leistungen</h1>
        <h1 class="nav-bar-link hide-mobile">Leistungssprektrum</h1>
        <p>Hier finden Sie einen Überblick der angebotenen Leistungen. Sollten Sie Fragen haben, können Sie sich selbstverständlich bei uns melden und einen Termin vereinbaren.</p>
        <br>
        <div class="alert" style="text-align: left;">
            <h3>Wichtiger Hinweis:</h3>
            <p>Wir behandeln sowohl gesetzlich als auch privat versicherte Patienten. Selbstverständlich bieten wir allen Patienten alle unsere Leistungen an. Leider werden aber einige Leistungen von den gesetzlichen Krankenkassen nicht übernommen. Gerne informieren wir Sie darüber im Detail. Sprechen Sie uns an!</p>    
        </div>
    </div>
    <div class="card">
        <h1>Vorsorgeuntersuchungen</h1>
        <p>Vorbeugen ist besser als Heilen. Vorsorgeunter-suchungen haben zum Ziel, Krankheiten schon in ihrer Entstehung zu erkennen und so eine frühzeitige Behandlung zu ermöglichen. Diese ist ganz besonders durch eine individuell ausgewählte Ernährung und Lebensweise, im Besonderen aber durch die Möglich-keiten der Klassischen Homöopathie und Chinesischen Medizin möglich. Wir bieten Ihnen deshalb eine Vielzahl unterschiedlicher Vorsorgeuntersuchungen an.</p>
        <h3>Angebotene Vorsorgeuntersuchungen</h3>
        <ul>
            <li>Gesundheits-Check</li>
            <li>Krebs-Vorsorge</li>
            <li>Herz- und Kreislaufuntersuchung</li>
            <li>Schilddrüsen-, Gefäß- und Kardio-Check</li>
            <li>Untersuchung des Stoffwechsels und der Verdauung</li>
            <li>Fitness-Check</li>
        </ul>
        <div class="alert">Häufig übernehmen die gesetzlichen und privaten Krankenkassen die Kosten für Vorsorgeuntersuchungen.</div>
    </div>
    <div class="double-card">
        <div class="card card-1-2">
            <h3>Schulmedizin</h3>
            <ul>
                <li>Hausärztliche Betreuung</li>
                <li>Herz- und Kreislaufuntersuchungen</li>
                <li>Check-up</li>
                <li>Krebsvorsorge Männer</li>
                <li>Kindervorsorgen U2 - U9</li>
                <li>Jugendschutzuntersuchung (J1, J2)</li>
                <li>Impfungen</li>
                <li>Laboruntersuchungen</li>
                <li>Ultraschalluntersuchung</li>
                <li>Lungenfunktionsmessung</li>
                <li>Langzeit-EKG</li>
                <li>24-Stunden-Blutdruckmessung</li>
                <li>Belastungs-EKG</li>
                <li>Sporttauglichkeitsuntersuchung</li>
                <li>Tauchtauglichkeitsuntersuchung</li>
                <li>Reiseberatung</li>
                <li>Allergietests</li>
                <li>Schlafuntersuchung-Schlafapnoe-Diagnostik</li>
                <li>Schmerztherapie</li>
                <li>TENS, Ultraschall, Reizstrom</li>
                <li>Iontophorese</li>
                <li>Diabetologisch geschulter Hausarzt</li>
                <li>Hausbesuche, Altenheimbetreuung</li>
            </ul>
        </div>
        <div class="card card-1-2">
            <h3>Naturheilverfahren</h3>
            <ul>
                <li class="link"><a href="/info?id=KlassischeHomoeopathie.html">Klassische Homöopathie</a></li>
                <li class="link"><a href="/info?id=TCM.html">Traditionelle Chinesische Medizin (TCM)</a></li>
                <li><span class="link"><a href="/info?id=Akupunktur.html">Akupunktur</a></span>, Ohrakupunktur</li>
                <li><span class="link"><a href="/info?id=Akupressur.html">Akupressur</a></span>, <span class="link"><a href="/info?id=Moxa.html">Moxa</a></span>, <span class="link"><a href="/info?id=Schroepfen.html">Schröpfen</a></span></li>
                <li class="link"><a href="/info?id=KraeuterTCM.html">Chinesische Kräuterheilkunde</a></li>
                <li class="link"><a href="/info?id=ChinesischeDiaetetik.html">Chinesische Diätetik</a></li>
                <li>Individuelle Ernährungsberatung</li>
                <li>Mikrobiologische Darmbehandlung, Symbioselenkung</li>
                <li>Westliche Heilpflanzenkunde</li>
                <li>Schüssler-Salze</li>
                <li>Bachblütentherapie</li>
                <li>Orthomolekulare Medizin</li>
                <li>Neuraltherapie</li>
                <li>Infusions- und Aufbaukuren</li>
                <li>Naturheilkundliche Schmerztherapie</li>
                <li>Eigenblutbehandlung</li>
                <li>Biologische Krebstherapie</li>
            </ul>
        </div>
    </div>
</div>