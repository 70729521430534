import { DynamicContentComponent } from './dynamic-content/dynamic-content.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ServicesComponent } from './services/services.component';
import { OpeningHoursComponent } from './opening-hours/opening-hours.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImprintComponent } from './imprint/imprint.component';
import { DownloadsComponent } from './downloads/downloads.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'sprechzeiten', component: OpeningHoursComponent },
  { path: 'leistungen', component: ServicesComponent },
  { path: 'impressum', component: ImprintComponent },
  { path: 'datenschutz', component: PrivacyComponent },
  { path: 'info', component: DynamicContentComponent },
  { path: 'downloads', component: DownloadsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
