<div class="main-container">
  <div class="card">
    <h1>Herzlich Willkommen<br />in der Praxis Dr. Mauch</h1>
    <p>
      Auf dieser Internetseite können Sie unsere Arztpraxis mit Schwerpunkt
      Naturheilverfahren kennen lernen. Im Rahmen unserer hausärztlichen
      Tätigkeit bieten wir Ihnen das gesamte Spektrum der allgemeinmedizinischen
      Schulmedizin an. Zusätzlich können Sie sich hier fachkundig mit Methoden
      der Naturheilverfahren behandeln lassen. Nützen Sie unser Angebot und
      vereinbaren Sie einen Termin.
    </p>
  </div>
  <div class="alert">
    <p>
      Ärztlichen Bereitschaftsdienst Bayern jederzeit erreichbar unter:
      <a href="tel:01805191212">01805 19 12 12</a><br />
      <b>In Notfällen:</b> Rettungsleitstelle Regensburg
      <a href="tel:094119222">0941 19222</a>
    </p>
  </div>
  <div class="double-card">
    <div class="card card-1-2">
      <h2 class="margin-bottom-0">Sprechzeiten<br />Dr. Andreas Mauch</h2>
      <p class="margin-top-0">Hausarzt</p>
      <div class="grid-time">
        <div>
          <p>Mo:<br />&nbsp;</p>
          <p>Di, Do:</p>
          <p>Mi:</p>
        </div>
        <div style="text-align: right">
          <p>8:00 - 12:30 Uhr<br />16:30 - 19:00 Uhr</p>
          <p>8:00 - 12:30 Uhr</p>
          <p>15:00 - 19:30 Uhr</p>
        </div>
      </div>
      <p style="text-align: center">Weitere Termine nach Vereinbarung.</p>
    </div>
    <div class="card card-1-2">
      <h2>Kontakt</h2>
      <div class="contact">
        <a
          href="https://www.google.com/maps/search/?api=1&query=Dr+Mauch+Schierling+Am+Anger+6"
        >
          <p>
            <img class="icon map" src="assets/icons/map.svg" alt="map-icon" />Am
            Anger 6<br /><span class="padding-left">84069 Schierling</span>
          </p>
        </a>
        <a href="mailto:info@dr-andreas-mauch.de">
          <p>
            <img
              class="icon"
              src="assets/icons/mail.svg"
              alt="mail-icon"
            />info@dr-andreas-mauch.de
          </p>
        </a>
        <a href="tel:+4994513998">
          <p>
            <img
              class="icon"
              src="assets/icons/phone.svg"
              alt="phone-icon"
            />+49 9451 - 3998
          </p>
        </a>
        <a href="tel:+491707137222">
          <p>
            <img
              class="icon"
              src="assets/icons/mobil.svg"
              alt="phone-icon"
            />+49 170 71 37 222 (Praxishandy)
          </p>
        </a>
      </div>
    </div>
  </div>
  <div class="card">
    <h2>Videosprechstunde</h2>
    <p>
      Wir verstehen gut, wenn Sie im Augenblick die Praxis ungern aufsuchen. Sie
      können mit mir eine Videosprechstunde vereinbaren. Dazu hinterlassen Sie
      bitte Ihre Handynummer und Ihre E-Mail-Adresse in der Praxis. Ich werde
      Sie dann anrufen und eine Verbindung zu Ihrem Computer herstellen.
    </p>
  </div>
</div>
