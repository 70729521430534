import { Component } from '@angular/core';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.sass'],
})
export class DownloadsComponent {
  constructor() {}
}
